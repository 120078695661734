<template>
  <div id="pnProducts">
    <div class="w-screen section-container laybare-products-banner relative space-top"></div>
    <div class="w-screen h-96 section-container laybare-products-banner-coming-soon-1 relative">
      <div class="flex justify-center items-center h-full text-white-1">
        <div class="relative inline-block">
          <img src="../../assets/images/services-page/sketch_line.png" alt="Sketch" class="w-12 mobileL:w-44 absolute -right-6 mobileL:-right-48 top-0">
          <h2 class="relative font-kannada text-4xl mobileL:text-7xl z-10">Coming Soon</h2>
          <img class="absolute w-5 mobileL:w-16 -left-5 mobileL:-left-16 -top-5 mobileL:-top-10 z-10" src="../../assets/images/home-page/shine.png" alt="Shine">
        </div>
      </div>
    </div>
    <div class="w-screen section-container laybare-products-banner-coming-soon-2 relative">
      <div class="flex justify-center items-center h-full text-white-1">
        <div class="relative inline-block">
          <img src="../../assets/images/services-page/sketch_line.png" alt="Sketch" class="w-12 mobileL:w-44 absolute -right-6 mobileL:-right-48 top-0">
          <h2 class="relative font-kannada text-4xl mobileL:text-7xl z-10">Coming Soon</h2>
          <img class="absolute w-5 mobileL:w-16 -left-5 mobileL:-left-16 -top-5 mobileL:-top-10 z-10" src="../../assets/images/home-page/shine.png" alt="Shine">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .section-container {
    height: 600px;
  }

  .space-top {
    margin-top: 90px;
  }
  
  .laybare-products-banner {
    background: url('../../assets/images/product-page/pn-products-banner.png') no-repeat;
    background-size: 100% 100%;
  }

  .laybare-products-banner-coming-soon-1 {
    background: url('../../assets/images/product-page/coming-soon-1.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .laybare-products-banner-coming-soon-2 {
    background: url('../../assets/images/product-page/coming-soon-2.jpg') no-repeat;
    background-size: 100% 100%;
  }

  @media (max-width: 480px){
    .section-container {
      height: 272px;
    }

    .space-top {
      margin-top: 60px;
    }

  }

  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: 800px;
    }
  }

  @media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }
  }
</style>